body {
  --dot-bg: #f4f5f8;
  --dot-color: #93a3c4;
  --dot-size: 1px;
  --dot-space: 22px;
  --clr-border: #0e97ae;
  --AnimationLength: 0px;
  font-family: "Jost", sans-serif !important;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--atlantic-100);
  border-radius: 8px;
}

.mask1 {
  /* Apply the mask */
  mask-image: url("./images/mask.png"); /* Replace with your actual mask image */
  mask-size: 100%;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/mask.png");
  -webkit-mask-size: 100%;
  -webkit-mask-position: top;
  -webkit-mask-repeat: round;
}

.mask1-mobile {
  /* Apply the mask */
  mask-image: url("./images/mask-mobile.png"); /* Replace with your actual mask image */
  mask-size: 100%;
  mask-position: auto;
  mask-repeat: no-repeat;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/mask-mobile.png");
  -webkit-mask-size: 100vh;
  -webkit-mask-position: top;
  -webkit-mask-repeat: no-repeat;
}

.mask2 {
  /* Apply the mask */
  mask-image: url("./images/mask2.png"); /* Replace with your actual mask image */
  mask-size: 100%;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/mask2.png");
  -webkit-mask-size: 100%;
  -webkit-mask-position: top;
  -webkit-mask-repeat: round;
}

.mask2-cover {
  /* Apply the mask */
  mask-image: url("./images/mask2.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/mask2.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: round;
}

.mask2-mobile {
  /* Apply the mask */
  mask-image: url("./images/mask2-mobile.png"); /* Replace with your actual mask image */
  mask-size: 100%;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/mask-mobile.png");
  -webkit-mask-size: 100vh;
  -webkit-mask-position: top;
  -webkit-mask-repeat: no-repeat;
}

.web-1 {
  /* Apply the mask */
  mask-image: url("./images/Masks/Web-2.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: no-repeat;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Web-2.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: no-repeat;
}

.web-2 {
  /* Apply the mask */
  mask-image: url("./images/Masks/Web-3.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Web-3.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: round;
}

.web-1 {
  /* Apply the mask */
  mask-image: url("./images/Masks/Web-1.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Web-1.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: round;
}

.footer-mask {
  /* Apply the mask */
  mask-image: url("./images/Masks/Footer.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: no-repeat;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Footer.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: no-repeat;
}

.mob-1 {
  /* Apply the mask */
  mask-image: url("./images/Masks/Mobile-1.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: round;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Mobile-1.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: round;
}

.mob-2 {
  /* Apply the mask */
  mask-image: url("./images/Masks/Mobile-2.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: no-repeat;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Mobile-2.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: no-repeat;
}

.mob-3 {
  /* Apply the mask */
  mask-image: url("./images/Masks/Mobile-3.png"); /* Replace with your actual mask image */
  mask-size: cover;
  mask-position: top;
  mask-repeat: no-repeat;

  /* Fallback for WebKit browsers */
  -webkit-mask-image: url("./images/Masks/Mobile-3.png");
  -webkit-mask-size: cover;
  -webkit-mask-position: top;
  -webkit-mask-repeat: no-repeat;
}

.bg-mask-position {
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
}

.table-row:hover {
  background-color: var(--astrology-mob-nav-bg);
}
